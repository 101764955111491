.admin-sidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 20%;
    height: 100vh;
    background: #111827;
    transition: all 0.3s linear;
    overflow: hidden;
    white-space: nowrap;
    z-index: 85;
}

.admin-sidebar-close{
    transition: all 0.3s linear;
    overflow: hidden;
    width: 0%;
    white-space: nowrap;
}


.as-inner-conatiner{
    width: 100%;
    max-width: 90%;
    margin: 0px auto;
    height: 100%;
}

.as-inner-conatiner .sidebar-admin-head{
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

}

.sidebar-admin-head .sa-img{
    width: 100px;
    height: 100px;
    background: white;
    position: relative;
    border-radius: 50%;
}


.sidebar-admin-head .sa-img img{
    width: 100%;
    object-fit: contain;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.sidebar-admin-head .sa-contnet{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
}

.sidebar-admin-head .sa-contnet h3{
    font-size: 25px;
    font-weight: 500;
    color: #FFFFFF;
}

.sidebar-admin-head .sa-contnet P{
    font-weight: 400;
    color: #94a3b8;
}

.main-links{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
}

.main-links a{
    display: inline-block;
    text-decoration: none;
    color: #FFFFFF;
    background:transparent;
    width: 100%;
    padding: 18px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 6px;
}

.main-links a:hover{
    background: rgb(255 255 255 / 0.12);
}

.main-links a.active-ap{
    background: rgb(255 255 255 / 0.12);
}

.main-links a svg{
    flex-basis: 15%;
    font-size: 20px;
}

.main-links a span{
    flex-basis: 85%;
    font-size: 17px;
}

@media screen and (min-width:200px) and (max-width:1300px){
    .admin-sidebar , .admin-sidebar-close{
        left: -100%;
        transition: all 0.3s linear;
        width: 100%;
        max-width: 300px;
    }

    .admin-sidebar-open{
        left: 0%;
        transition: all 0.3s linear;
    }
}

@media screen and (min-width:200px) and (max-width:400px){
    .admin-sidebar , .admin-sidebar-close{
        max-width: 250px;
    }
    .sidebar-admin-head .sa-contnet h3{
        font-size: 18px;
    }
}