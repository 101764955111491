.ad-nav{
    width: 100%;
    background: #FFFFFF;
    padding: 15px 0px;
    box-sizing:  0 1px 3px 0 rgba(0 , 0 , 0 , 0.1);
}

.ad-nav-inner-conatiner{
    width: 100%;
    max-width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right-flex-ad{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.right-flex-ad img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}



.mobile-menu{
    display: none;
}

.Logout-btn{
    padding: 8px 12px;
    border-radius: 12px;
    border: none;
    outline: none;
    background: rgba(246, 13, 24, 0.15);
    color: #EF1D26;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
}

.Logout-btn svg{
    color: #EF1D26;
    font-size: 22px;
    cursor: pointer;
}

.admin-menu{
    cursor: pointer;
    font-size: 35px;
    color: #4b5666;
}


@media screen and (min-width:200px) and (max-width:1300px){
    .mobile-menu{
        display: block;
    }

    .desktop-menu{
        display: none;
    }
    
}