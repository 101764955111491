.conatct-container{
    width: 95%;
    max-width: 1100px;
    margin: 100px auto;
    position: relative;
}

.conatct-container img{
    position: absolute;
    right: 0px;
    top: -50px;
    width: 1000px;
    opacity: 0.9;
    z-index: -1;
}

.inner-container-contact{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.left-contact-container{
    background: #FFFFFF;
    flex-basis: 50%;
}

.left-contact-inner-container{
    padding: 42px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 28px;
}

.right-contact-container{
    flex-basis: 50%;
}

.contact-details{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
    width: 100%;
}


.contact-details svg{
    font-size: 23px;
    color: #EF1D26;
}

.contact-details  p{
    color: #757F95;
    line-height: 22px;
    font-weight: 400;
    width: 100%;
}

.contact-details  a{
    text-decoration: none;
}

.contact-details a h3{
    color: #111111;
}

.contact-details .contnet{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 3px;
    flex-grow: 1;
}

.left-contact-inner-container p{
    color: #757F95;
    line-height: 22px;
    font-weight: 400;
    width: 100%;
}


.contact-social{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap:8px;
}

.contact-social a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF;
    background: #EF1D26;
    padding: 10px;
    border-radius: 50%;
    font-size: 22px;
}

.right-contact-container-head p{
    margin: 18px 0px 25px;
    color: #757F95;
    line-height: 22px;
    font-weight: 400;
}

.right-contact-container-head h2{
    font-size: 35px;
    font-weight: 800;
    color: #111111;
}

.custom-input-feild{
    background-color: #FFFFFF;
    margin: 10px 0px;
    border-radius: 3px;
    flex-basis: 50%;
    padding: 18px 13px;
}

.custom-input-feild input , .custom-input-feild textarea{
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.custom-input-feild input::placeholder , .custom-input-feild textarea::placeholder{
    color: #474f60;
    line-height: 22px;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.email-phone{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-basis: 50%;
}

.contact-message{
    padding: 8px 13px;
}

@media screen and (min-width:200px) and (max-width:1000px){
    .left-contact-inner-container{
        padding: 42px 25px;
    }
}

@media screen and (min-width:200px) and (max-width:800px){
    .inner-container-contact{
        flex-direction: column;
        gap: 40px;
    }
    .conatct-container img{
        top: 50%;
        width: 800px;
        left: 0px;
        opacity: 1;
    }
}


.location-content{
    border-radius: 8px;
    margin: 30px 0px;
    width: 100%;
    height: 300px;
}

@media screen and (min-width:200px) and (max-width:450px){
    .contact-details  p{
        font-size: 14px;
    }
}

@media screen and (min-width:200px) and (max-width:420px){
    .left-contact-inner-container{
        padding: 42px 15px;
    }
}