.preloader-admin{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.preloader-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.preloader-content img{
    width: 250px;
    object-fit: contain;
}