.about-container{
    position: relative;
    margin: 80px auto;
    /* z-index: -10; */
}

.about-inner-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 50%;
    gap: 40px;
}

.about-left{
    position: relative;
    flex-basis: 50%;
    z-index: -1;
}

.about-right{
    position: relative;
    flex-basis: 50%;
}


.about-left img{
    max-width: 100%; /* Ensure the image doesn't exceed its container's width */
    height: auto;    /* Maintain the image's aspect ratio */
    display: block;
    object-fit: cover;
}

.about-right-inner-container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}

.about-right-inner-container .sub-tittle{
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    color: #EF1D26;
    font-size: 17px;
    letter-spacing: 3px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    z-index: -10;
}

.about-right-inner-container h3{
    color: #111111;
    font-size: 50px;
    font-weight: 800;
    margin: 20px 0;
    text-transform: capitalize;
    margin: 0px;
    z-index: -10;
}

.about-right-inner-container h3 span{
    color: #EF1D26;
}

.Points p{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin: 15px 0px;
    color: #757F95;
    font-size: 15px;
    font-weight: 500;
}

.Points p svg{
    color: #EF1D26;
}

.about-right .descripbtion{
    color: #757F95;
    line-height: 22px;
    font-weight: 400;
}

.expeerience{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: #111111;
    padding: 8px 18px 8px 8px;
    position: absolute;
    left: 0px;
    top: 20px;
    z-index: 1;
    border-radius: 12px;
}

.service-icon{
    padding: 10px;
    background: #EF1D26;
    border-radius: 12px;
    color: #FFFFFF;
}

.service-icon svg{
    font-size: 30px;
    color: #FFFFFF;
}

.expeerience p{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #FFFF;
    font-weight: 800;
    font-size: 15px;
}

@media screen and (min-width:200px) and (max-width:1120px){
    .about-right-inner-container h3{
        font-size: 40px;
    }
    .about-left{
        flex-basis: 40%;
    }
    .about-right{
        position: relative;
        flex-basis: 60%;
    }
    .about-left img{
        position: relative;
        width: 400px;
    }
}

@media screen and (min-width:200px) and (max-width:900px){
    .about-right-inner-container h3{
        font-size: 30px;
    }
    .about-left{
        flex-basis: 40%;
    }
    .about-right{
        position: relative;
        flex-basis: 60%;
    }
    .about-left img{
        position: relative;
        width: 350px;
    }
    
}

@media screen and (min-width:200px) and (max-width:800px){
    .about-right-inner-container h3{
        font-size: 30px;
    }
    .about-left{
        flex-basis: 50%;
    }
    .about-right{
        position: relative;
        flex-basis: 50%;
    }
    .about-left img{
        position: relative;
        width: 400px;
    }
    .about-inner-container{
        flex-direction: column-reverse;
    }
    
}