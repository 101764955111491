.image-slider-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 80;
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    transition: all 0.3s linear;
}

.image-slider-container-open{
    pointer-events: unset;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.904);
    transition: all 0.3s linear;
}

.image-container{
    position: relative;
    width: 95%;
    max-width: 800px;
    margin: auto;
    height: 600px;
    border-radius: 12px;
    background: #FFFFFF;
}

.slider-conatiner{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.image-container .each-slider-image{
    width: 100%;
    object-fit: cover;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;
}

.cancel-image-slider{
    position: fixed;
    top: 12px;
    right: 12px;
    background: rgb(92, 92, 92);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 95;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-weight: 800;
    font-size: 25px;
    cursor: pointer;
}

.copyright-img{
    position: absolute;
    top: 12px;
    left: 12px;
    width: 150px;
    object-fit: contain;
    z-index: 1;
}

.arrow-image-slider{
    background: rgba(255, 255, 255, 0.801);

}

.arrow-image-slider svg{
    color: #000000;
}
@media screen and (min-width:200px) and (max-width:550px) {
    .image-container{
         height: 400px;
    }
    .copyright-img{
        width: 60px;
    }
}

@media screen and (min-width:200px) and (max-width:1120px){
    .arrow-image-slider{
        top: 50%;
    }
    .arrow-image-slider-prev{
        left: 0%;
        z-index: 1;
    }
    
    .arrow-image-slider-next{
        right: 0%;
    } 
}

@media screen and (min-width:200px) and (max-width:400px){
    .image-container{
        height: 350px;
   }
}