.ab-banner{
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    height: 55vh;
    width: 100%;
}

.ab-banner p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    color: #FFFFFF;
    font-weight: 800;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.ab-banner p a{
    color: #FFFFFF;
}

.ab-banner p span{
    color:#EF1D26 ;
}

@media screen and (min-width:200px) and (max-width:1050px){
    .ab-banner{
        height: 35vh;
    }
}

