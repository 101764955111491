.director-inner-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.direcrot-container{
    position: relative;
    margin: 80px auto 120px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.director-image{
    flex-basis: 40%;
    height: 430px;
}

.director-speech{
    flex-basis:60%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
}


.director-image img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 22px;
}


.director-speech h1{
    color: #111111;
    font-weight: 800;
    font-size: 30px;
}

.director-speech h1 span{
    color: #EF1D26;
}

.director-speech p{
    color: #757F95;
    line-height: 27px;
    font-weight: 400;
}

.director-speech .desccription{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.rest-op-speech {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap:20px;
}

.rest-op-speech .speech{
    color: #757F95;
    line-height: 27px;
    font-weight: 400;
}


.rest-op-speech .signatute img{
    width: 130px;
    object-fit: contain;
    margin-bottom: 8px;
}

.rest-op-speech .signatute p span{
    color: #EF1D26;
}

@media screen and (min-width:200px) and (max-width:1000px){
    .director-image{
        flex-basis: 50%;
        height: 600px;
    }
    
    .director-speech{
        flex-basis:50%;
    }
}

@media screen and (min-width:200px) and (max-width:800px){
    .director-inner-container{
        flex-direction: column;
        gap: 25px;
    }
    .director-image{
        width: 100%;
        flex-basis: 100%;
        height: 450px;
    }
    
    .director-speech{
        width: 100%;
        flex-basis:100%;
    }
}

@media screen and (min-width:200px) and (max-width:400px){
    .director-speech h1{
        font-size: 23px;
    }
    
}