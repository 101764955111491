.full-new-arrivals-container{
    width: 100%;
    padding: 100px 0px;
    background: #FFFFFF;
}

.comman-head{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
}

.comman-head .sub-tittle-comman{
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    color: #EF1D26;
    font-size: 17px;
    letter-spacing: 3px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
}

.titlle-coman{
    color: #111111;
    font-size: 35px;
    font-weight: 800;
    margin: 20px 0;
    text-transform: capitalize;
    margin: 0px;
}

.titlle-coman span{
    color: #EF1D26;
}

.heading-divider{
    display: inline-block;
    position: relative;
    border-bottom: 4px solid #EF1D26;
    width: 90px;
    height: 4px;
}

.heading-divider::after{
    content: '';
    position: absolute;
    top: 0px;
    height: 15px;
    width: 15px;
    border-radius: 0;
    background-color: #F9F9F9;
    -webkit-animation: heading-move 3s infinite linear;
    animation: heading-move 3s infinite linear ;
    z-index: 1;
}

@keyframes heading-move {
    0%{
        left: 0%;
    }
    5%{
        left: 10%;
    }
    10%{
        left: 20%;
    }
    15%{
        left: 30%;
    }
    20%{
        left: 40%;
    }
    25%{
        left:50%;
    }
    30%{
        left: 60%;
    }
    35%{
        left: 70%;
    }
    40%{
        left: 80%;
    }
    45%{
        left: 90%;
    }
    50%{
        left: 100%;
    }
    55%{
        left:90%;
    }
    60%{
        left:80%;
    }
    65%{
        left:75%;
    }
    70%{
        left:60%;
    }
    75%{
        left:50%;
    }
    80%{
        left:40%;
    }
    85%{
        left:30%;
    }
    90%{
        left:20%;
    }
    95%{
        left:10%;
    }
    100%{
        left:0%;
    }
}

.new-arrival-items-container{
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,0.4fr));
    margin: 60px auto 0px auto;
}

.na-product-container{
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
    padding: 10px 10px 0px;
    border-radius: 12px;
    cursor: pointer;
}

.na-button-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.na-button-container button{
    flex-basis: 50%;
}

.na-btn{
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.na-btn::before{
    background: #EF1D26;
}


.na-product-inner-container .na-img{
    position: relative;
    height: 270px;
    border-radius: 12px;
    overflow: hidden;
}

.copyright-nyk{
    position: absolute;
    top: 12px;
    left: 12px;
    width: 80px;
    object-fit: contain;
    z-index: 1;
}

.na-image-show{
    position: absolute;
    top: 12px;
    right: 12px;
    width: 25px;
    height: 25px;
    z-index: 1;
    background: #EF1D26;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}

.vehi-status{
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 10px;
    padding: 6px 10px;
    color: #FFFFFF;
    font-weight: 800;
    z-index: 3;
}

.new-veh{
    background: #11B76B;
}

.old-veh{
    background: #EF1D26;
}

.na-img .vehicale-image{
    width: 100%;
    object-fit: cover;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;
    z-index: 2;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.2s all linear;
}

.na-img .vehicale-image:hover{
    transform: scale(1.1);
    transition: 0.2s all linear;
}

.items-deatils {
    margin-top: 15px;
}

.items-deatils h4{
    color: #111111;
    font-size: 18px;
    font-weight: 600;
}

.item-option{
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
}

.item-option div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.item-option div svg{
    color: #EF1D26;
}

.item-option div span{
    color: #757F95;
    line-height: 22px;
    font-size: 14px;
}

.price-na{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
}

.na-button-container{
    border-top: 1px solid #757F95;
    padding: 20px 0px 0px;
}


.price-na h3{
    color: #EF1D26;
    font-weight: 600px;
    font-size: 18px;
}

.price-na .theme-btn{
    padding: 10px 13px;
    border-radius: 10px;
}

@media screen and (min-width:200px) and (max-width:650px) {
    .new-arrival-items-container {
        grid-template-columns: 1fr; /* Set child elements to take full width */
    }
}

@media screen and (min-width:200px) and (max-width:380px) {
    .titlle-coman{
        font-size: 28px;
    }
    
}

@media screen and (min-width:200px) and (max-width:550px) {
    .new-arrival-items-container {
        grid-template-columns: 1fr; /* Set child elements to take full width */
    }
}

.sub-contact{
    margin-top: 80px;
}

.innner-sc-containe{
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.left-sc{
    position: relative;
    flex-basis: 65%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    color: #FFFFFF;
}

.left-sc::before {
    position: absolute;
    content: close-quote;
    width: 2px;
    bottom: 0;
    background-color: #FFFFFF;
    right: -40px;
    top: 0;
}

.left-sc::after{
    width: 0;
    position: absolute;
    content: close-quote;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #FFFFFF;
    top: 50%;
    transform: translateY(-50%);
    right: -45px;
}

.right-sc{
    flex-basis: 35%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    gap: 20px;
}

.left-sc h2{
    font-size: 50px;
    font-weight: 600;
}


.left-sc p{
    font-size: 15px;
}

.right-sc h2{ 
    font-size: 30px;
    font-weight: 600;
    color: #FFFFFF;
}

.right-sc h2 a{
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF;
}


.sub-contact{
    width: 100%;
    margin-top: 80px;
    background-image: url("../../../assets//Basic-Needs/sub-contact.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    border-radius: 15px;
    z-index: 1;
}

.sub-contact::before{
    content: "";
    position: absolute;
    background: #EF1D26;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: .85;
    z-index: -1;
}

.theme-btn-3::before{
    background: #111111;
}

@media screen and (min-width:200px) and (max-width:1000px) {
    .left-sc::before , .left-sc::after {
        display: none;
    }
    .innner-sc-containe{
        flex-direction: column;
    }
    .left-sc{
        flex-basis: 100%;
        align-items: center;
        text-align: center;
    }
    .right-sc{
        flex-basis: 100%;
        align-items: center;
    }
}

@media screen and (min-width:200px) and (max-width:650px){
    .left-sc h2{
        font-size: 35px;
    }
    .innner-sc-containe{
        padding: 40px 10px;
    }
    
} 

@media screen and (min-width:200px) and (max-width:450px){
    .left-sc h2{
        font-size: 28px;
    }
    
    
} 

@media screen and (min-width:200px) and (max-width:350px){
    .right-sc h2{ 
        font-size: 23px;
    }
    
} 