.Popup{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 0;
    opacity: 1;
    pointer-events: none;
    cursor: pointer;
    transition: all 0.3s linear;
    transition-delay: 0.4s;
}

.popup-open{
    z-index: 80;
    opacity: 1;
    pointer-events: unset;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s linear;
    transition-delay: 0.4s;
}