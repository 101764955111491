.request-box-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 80;
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    transition: all 0.3s linear;
}

.request-box-container-open{
    pointer-events: unset;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.904);
    transition: all 0.3s linear;
}

.request-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 95%;
    max-width: 600px;
    height: fit-content;
    margin: auto;
    border-radius: 12px;
    background: #FFFFFF;
}

.request-box-inner{
    width: 95%;
    max-width: 100%;
    margin: auto;
    padding: 10px 0px;
    height: fit-content;
}


.request-box-inner  h3{
    font-size: 25px;
    font-weight: 600;
    color: #111111;
}

.request-box-inner  h3 span{
    color: #EF1D26;
}

.mr-optins-request-box{
    margin: 20px 0px;
}


@media screen and (min-width:200px) and (max-width:450px){
    .request-box-inner  h3{
        font-size: 18px;
    }
    
}
