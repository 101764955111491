.show-fvrt-vehicale-container{
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,0.5fr));
    margin-top: 20px;
}

.show-fvrt-vehicale-container .na-product-container{
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;
}


.show-fvrt-vehicale-container .na-product-inner-container .na-img{
    position: relative;
    height: 180px;
    border-radius: 12px;
    overflow: hidden;
}

.show-fvrt-vehicale-container .vehi-status{
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 10px;
    padding: 6px 10px;
    color: #FFFFFF;
    font-weight: 800;
    z-index: 3;
}



.show-fvrt-vehicale-container .na-img img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;
    z-index: 2;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.2s all linear;
}

.show-fvrt-vehicale-container .na-img img:hover{
    transform: scale(1.1);
    transition: 0.2s all linear;
}

.show-fvrt-vehicale-container .items-deatils {
    margin-top: 15px;
}

.show-fvrt-vehicale-container .items-deatils h4{
    color: #111111;
    font-size: 18px;
    font-weight: 600;
}

.show-fvrt-vehicale-container .item-option{
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
}

.show-fvrt-vehicale-container .item-option div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.show-fvrt-vehicale-container .item-option div svg{
    color: #EF1D26;
}

.show-fvrt-vehicale-container .item-option div span{
    color: #757F95;
    line-height: 22px;
    font-size: 14px;
}

.show-fvrt-vehicale-container .price-na{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 0px;
    border-top: 1px solid #757F95;
    margin-top: 20px;
}

.show-fvrt-vehicale-container .price-na h3{
    color: #EF1D26;
    font-weight: 600px;
    font-size: 18px;
}

.show-fvrt-vehicale-container .price-na .theme-btn{
    padding: 10px 13px;
    border-radius: 10px;
}

@media screen and (min-width:200px) and (max-width:550px) {
    .show-fvrt-vehicale-container {
        grid-template-columns: 1fr; 
    }
}
