.show-case-fvrtt-vehicale{
    margin-top: 50px;
}

.cell-buttton-favrt{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border: none;
    outline: none;
    color: #6259CA;
    background: rgba(98, 89, 202, 0.15);
    border-radius: 12px;
    padding: 8px 12px;
    cursor: pointer;
}

.cell-buttton-favrt-danger{
    color: #EF1D26;
    background: rgba(239, 29, 38, 0.15);
}