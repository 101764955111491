.footer-full-container{
    width: 100%;
    background: #111111;
    margin-top: 70px;
}

.footer-inner-conatiner{
    padding: 70px 0px;
}

.footer-container{
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    display: grid;
    /* grid-template-columns: repeat(auto-fit,minmax(250px,0.5fr)); */
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
}

.fcol-01{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 18px;
    flex-direction: column;
    flex-basis: 30%;
    width: 100%;
}

.fcol-01 img{
    width: 150px;
    object-fit: contain;
}

.footer-col1-content-each{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.footer-col1-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    flex-direction: column;
}

.footer-col1-content a{
    text-decoration: none;
}

.location-p{
    text-transform: capitalize;
}

.fcol-01 p{
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

.col-01-icon{
    border-radius: 12px;
    background: #EF1D26;
    padding: 8px;
}

.col-01-icon svg{
    color: #FFFFFF;
    font-size: 25px;
}

.fcol-02-03{
    flex-basis: 20%;
    width: 100%;
}

.fcol-04{
    flex-basis: 20%;
    width: 100%;
}

.footer-head{
    font-size: 25px;
    font-weight: 600;
    color: #FFFFFF;
    position: relative;
    font-size: 23px;
}

.footer-head::before{
    position: absolute;
    content: '';
    z-index: 1;
    width: 90px;
    height: 2px;
    background: rgba(255, 255, 255, 0.2);
    bottom: -10px;
    left: 0;
}

.footer-head::after{
    position: absolute;
    content: '';
    z-index: 1;
    width: 30px;
    height: 2px;
    background-color: #EF1D26;
    bottom: -10px;
    left: 18px;
}

.fcol-02-03 .fcol-02-links{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    margin-top: 40px;
    flex-direction: column;
}

.fcol-02-links a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 500;
    transform: translateX(0px);
    transition: all .3s ease-in-out;
}

.fcol-02-links a svg{
    color: #EF1D26;
    font-size: 18px;
}

.fcol-02-links a:hover{
    color: #EF1D26;
    transform: translateX(10px);
    transition: all .3s ease-in-out;
}

.fcol-04-form{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    margin-top: 40px;
    flex-direction: column;
    width: 100%;
}

.footer-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.fcol-04 p{
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}


.subscribe-input{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    margin: 8px 0px;
    background: #FFFFFF;
}

.subscribe-input input{
    flex-basis: 95%;
    padding: 14px 12px;
    background: none;
    outline: none;
    border: none;
    color: #757F95;
    font-family: 'Roboto', sans-serif;
}

.search-bar-model svg{
    padding: 0px 5px;
    flex-basis: 15%;
    color: #757F95;
    font-size: 15px;
}

.subscribe-inputinput::placeholder{
    color: #757F95;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.footer-btn::before{
    background: #FFFFFF;
}

.footer-btn:hover{
    color: #111111;
}

.copyright{
    background: #181818;
    padding: 20px 0px;
    color: #FFFFFF;
    font-weight: 500;
    position: relative;
}

.copyright::before{
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    background:  #EF1D26;
    width: 50%;
    height: 100%;
    clip-path: polygon(10% 1%, 100% 0, 100% 100%, 0% 100%);
    z-index: 0;
}


.copyright p{
    position: relative;
    z-index: 1;
}


.copyright .conatiner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 10;
    gap: 8px;
}

.copyright .conatiner .design-flix a{
    color: #fff;
    font-weight: 700;
}

.copyright .conatiner .umair a{
    color: #EF1D26;
}

.copyright .conatiner p:nth-child(2){
    text-align: right;
}

@media screen and (min-width:200px) and (max-width:1000px) {
    
    .footer-container{
        grid-template-columns: repeat(auto-fit,minmax(250px,0.5fr));
        grid-column-gap: 2rem;
        grid-row-gap: 3rem;
    }
}


@media screen and (min-width:200px) and (max-width:600px) {
    .footer-container{
        grid-template-columns:1fr;
    }
}

@media screen and (min-width:200px) and (max-width:1000px){
    .copyright::before{
        display: none;
    }
    
    .copyright p{
        width: 100%;
        text-align: center;
    }
    .copyright .conatiner{
        flex-direction: column;
    }
    .copyright .conatiner p:nth-child(2){
        text-align: center;
    }
    .copyright .conatiner .design-flix a{
        color: #EF1D26;
        font-weight: 700;
    }
    .copyright .conatiner p{
        font-size: 14px;
    }
}

