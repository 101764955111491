.delete-conformation-full-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    transition: all 0.3s linear;
}

.delete-conformation-full-container-open{
    opacity: 1;
    pointer-events: unset;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s linear;
}


.delete-conformation-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 95%;
    margin: auto;
    max-width: 450px;
    background: #FFFFFF;
    border-radius: 12PX;
}

.delete-conformation-inner-container{
    width: 100%;
    max-width: 90%;
    margin: auto;
    padding: 20px 0px;
}

.delete-conformation-inner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.delete-conformation-inner-container .delte-confomation-svg{
    color: red;
    font-size: 140px;
}

.delete-conformation-button-continer{
    width: 100%;
    flex: 1;
}

.delete-conformation-inner-container p{
    text-align: center;
    color: #4b5666;
    font-size: 16px;
    width: 100%;
    font-weight: 500;
}