.we-are-full-container{
    position: relative;
    margin: 100px 0px;
    background: #FFFFFF;
    overflow: hidden;
}

.red-blur{
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 550px;
    height: 550px;
    background: rgba(252, 0, 18, 0.2);
    -webkit-filter: blur(200px);
    filter: blur(200px);
    border-radius: 50%;

}

.orange-blur{
    position: absolute;
    display: block;
    bottom: 0px;
    right: 0px;
    width: 550px;
    height: 550px;
    background: rgba(255, 151, 41, 0.2);
    -webkit-filter: blur(200px);
    filter: blur(200px);
    border-radius: 50%;
}

.red-span{
    color: rgba(252, 0, 17, 0.6);
}

.orange-span{
    color: rgba(255, 152, 41, 0.6);
}

.we-are-content{
    position: relative;
    width: 95%;
    max-width: 1100px;
    margin: auto;
    padding: 100px 0px;
    z-index: 2;
}

.we-are-content h1{
    text-align: center;
    font-weight: 800;
    font-size: 35px;
    margin: auto;
    max-width: 600px;
    width: 95%;
}

.main-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 80px
}

.left-content-we-are{
    flex-basis: 33%;
    /* background: green; */
    height: fit-content;
}

.middle-content-we-are{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 33%;
}

.right-content-we-are{
    flex-basis: 33%;
    height: fit-content;
}

.middle-content-we-are img{
    width: 400px;
}

.we-are-card{
    position: relative;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
    margin: 30px 0px;

}

.we-are-card-inner{
    padding: 40px 40px 40px 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    gap: 12px;
    text-align: right;
}

.we-are-card h4{
    font-weight: 600;
    font-size: 18px;
}

.we-are-card p{
    color: #757F95;
    line-height: 22px;
    font-weight: 400;
}

.we-are-icon{
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translateY(-50%);
    background: #FFFFFF;
    color: #EF1D26;
}

.we-are-icon svg{
    font-size: 22px;
    z-index: 5;
    box-shadow: 0px 6px 20px rgba(0, 56, 60, 0.2);
    padding: 15px;
    border-radius: 50%;
}

.we-are-card-right .we-are-card-inner{
    padding: 40px 10px 40px 40px;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

.we-are-card-right .we-are-icon{
    position: absolute;
    top: 50%;
    left: -25px;
    right: 100%;
}

@media screen and (min-width:200px) and (max-width:1000px){
    .main-content{
        gap: 80px;
    }
    .middle-content-we-are{
        display: none;
    }
    .left-content-we-are{
        flex-basis: 50%;
    }
    .right-content-we-are{
        flex-basis: 50%;
    }
}

@media screen and (min-width:200px) and (max-width:600px){
    .main-content{
        flex-direction: column;
        gap: 0px;
        width: 90%;
        max-width: 100%;
        margin: auto;
    }
}

@media screen and (min-width:200px) and (max-width:450px){

    .we-are-content h1{
        font-size: 30px;
    }
    
}

@media screen and (min-width:200px) and (max-width:450px){

    .we-are-content h1{
        font-size: 25px;
    }
    
}