.clinet-request-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    pointer-events: none;
    opacity: 0;
    background-color: transparent;
    transition: all 0.3s linear;
}

.clinet-request-container-open{
    pointer-events: unset;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s linear;
}

.clinet-request-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    max-width: 550px;
    width: 95%;
    border-radius: 12px;
    background: #FFFFFF;
    margin: auto;
}

.clinet-request-box-inner{
    width: 95%;
    max-width: 100%;
    margin: auto;
    padding: 15px 0px;
}

.clinet-request-box-inner h3 span{
    color: #EF1D26;
}

.request-personal-details{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 10px;
}

.persnal-details-head{
 margin: 8px 0px 15px;

}

.request-personal-details h4{
    font-size: 15px;
}

.request-personal-details h4 span:nth-child(2){
    color: #4c4c4c;
}

.request-personal-details h4 a{
    color: #4c4c4c;
    text-decoration: none;
}