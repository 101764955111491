.wcu-container {
  margin: 100px auto;
}

.full-wcu-container {
  width: 100%;
  background: #111111;
}

.wcu-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  padding: 80px 0px;
}

.wcu-left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  flex-basis: 50%;
}

.wcu-right {
  flex-basis: 50%;
}

.wcu-left img {
  position: relative;
  max-width:100%; /* Ensure the image doesn't exceed its container's width */
  height: auto; /* Maintain the image's aspect ratio */
  display: block;
  object-fit: cover;
  margin-top: 20px;
}

.wcu-left .sub-tittle {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  color: #ffffff;
  font-size: 17px;
  letter-spacing: 3px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
}

.wcu-left h3 {
  color: #ffffff;
  font-size: 45px;
  font-weight: 800;
  margin: 20px 0;
  text-transform: capitalize;
  margin: 0px;
}

.wcu-left h3 span {
  color: #ef1d26;
}

.wcu-left .descripbtion {
  color: #ffffff;
  line-height: 22px;
  font-weight: 400;
}

.wcu-right-inner-container{
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(200px,0.5fr));
}

.wcu-why-card {
  position: relative;
  background: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  border-radius: 12px;
}

.wcu-why-card .wcu-card-icon {
  background: #ef1d26;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 40px;

}

.wcu-why-card p{
    color: #757F95;
    line-height: 28px;
    font-weight: 400;
}

.wcu-card-icon h3{
    font-size: 35px;
    font-weight: 600;
}

.left-card{
    transform: translateY(30px);
}
.right-card{
    transform: translateY(-15px);
}

.wcu-why-card h2{
    position: absolute;
    right: 25px;
    top: 10px;
    font-size: 60px;
    font-weight: 800;
    -webkit-text-stroke: 1px #ef1d26;
    -webkit-text-fill-color: transparent;
    z-index: -1;
}

@media screen and (min-width:200px) and (max-width:1000px){
    .wcu-left {
        flex-basis: 45%;
    }
      
    .wcu-right {
        flex-basis: 65%;
    }
    .wcu-left h3 {
        font-size: 35px;
    }
      
}

@media screen and (min-width:200px) and (max-width:900px){
    .wcu-left {
        flex-basis: 50%;
    }
      
    .wcu-right {
        flex-basis: 50%;
        width: 100%;
    }
    .wcu-left h3 {
        font-size: 35px;
    }
    .wcu-inner-container {
        flex-direction: column;
    }
    .wcu-right-inner-container{
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(200px,0.5fr));
    }
    .left-card{
        transform: translateY(0px);
    }
    .right-card{
        transform: translateY(0px);
    }
}


@media screen and (min-width:200px) and (max-width:440px) {
    .wcu-right-inner-container {
        grid-template-columns: 1fr; /* Set child elements to take full width */
    }
}


@media screen and (min-width:200px) and (max-width:380px) {
    .wcu-left h3 {
        font-size: 30px;
    }
}