.show-case-container{
    margin-top: 30px;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,0.5fr));
}

.sc-each{
    height: fit-content;
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    text-decoration: none;
}

.sc-each .sc-each-contnet{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.sc-each .sc-each-contnet h4{
    font-size: 25px;
    font-weight: 600;
}

.sc-each .sc-each-contnet p{
    font-size: 25px;
    font-weight: 600;
}

.sc-each .sc-each-svg{
    width: 50px;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.sc-each .sc-each-svg svg{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 27px;
}

.sc-each-01{
    color: #6259CA;
    background: rgba(98, 89, 202, 0.15);
}

.sc-each-01 .sc-each-svg{
    background: #6259CA;
}

.sc-each-02{
    color: #29BF6C;
    background: rgba(14, 198, 198, .15);
}

.sc-each-02 .sc-each-svg{
    background: #29BF6C;
}

.sc-each-03{
    color: #EF1D26;
    background: rgba(239, 29, 38, 0.15);
}

.sc-each-03 .sc-each-svg{
    background: #EF1D26;
}

@media screen and (min-width:200px) and (max-width:650px){
    .show-case-container{
        grid-template-columns:1fr;
    }
}

@media screen and (min-width:200px) and (max-width:400px){
    .sc-each .sc-each-contnet h4{
        font-size: 20px;
    }
}