.Banner{
    position: relative;
    background-position: center;
    background-size:cover;
    height: 105vh;
    width: 100%;
}

.theme-btn-2{
    font-size: 16px;
    color: #EF1D26;
    padding: 16px 20px;
    transition: all .5s;
    text-transform: capitalize;
    position: relative;
    border-radius: 13px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    border: none;
    background: #FFFFFF;
    z-index: 1;
}

.theme-btn-2::before{
    content: "";
    height: 300px;
    width: 300px;
    background: #EF1D26;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: .5s cubic-bezier(.25,.46,.45,.94);
    z-index: -1;
}

.theme-btn-2:hover::before {
    transform: translateY(-50%) translateX(-50%) scale(1);
  }

.theme-btn-2 a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #EF1D26;
    transition:  linear 0.1s;
    text-decoration: none;
}

.theme-btn-2:hover a{
    color: #FFFFFF;
    transition:  linear 0.1s;
}

.inner-container{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.inner-container .content{
    display: flex;
    flex-direction: column;
    gap: 22px;
    /* background: green; */
    width: 100%;
    max-width: 700px;
    padding: 20px 0px;
}

.button-groups{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.sub-head{
    color: #c87575;
    font-size: 20px;
    letter-spacing: 6px;
    font-weight: 1000;
    position: relative;
    text-transform: uppercase;
}

.sub-head span{
    color: #EF1D26;
    font-size: 26px;
}

.content h3{
    color: #FFFFFF;
    font-size: 50px;
    font-weight: 800;
    margin: 20px 0;
    text-transform: capitalize;
    margin: 0px;
}

.describtion{
    color: #FFFFFF;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 20px;
}

.content h3 span{
    -webkit-text-stroke: 2px #EF1D26;
    -webkit-text-fill-color: transparent;
}

.Arrow{
    position: absolute;
    color: #aa1212;
    font-size: 25px;
    margin: 0;
    padding: 0;
    background: rgba(255, 255, 255, 0.2);
    display: inline-block;
    cursor: pointer;
    height: 55px;
    width: 55px;
    line-height: 55px;
    border-radius: 50px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: linear 0.2s;
}

.Arrow svg{
    font-weight: 800;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

.prev-Arrow{
    left: 3%;
    z-index: 1;
}

.next-Arrow{
    right: 3%;
}

.Arrow:hover{
    background: #FFFFFF;
    transition: linear 0.2s;
}

.Arrow:hover svg{
    color: #EF1D26;
    transition: linear 0.2s;
}

.br{
    display: none;
}

@media screen and (min-width:200px) and (max-width:610px){
    .content h3{
        font-size: 40px;
    }
}


@media screen and (min-width:200px) and (max-width:500px){
    .content h3{
        font-size: 35px;
    }
    .sub-head{
        font-size: 14px;
    }
    
    .sub-head span{
        font-size: 22px;
    }
}

@media screen and (min-width:200px) and (max-width:420px){
    .sub-head span{
        margin-top: 20px;
    }
}

@media screen and (min-width:200px) and (max-width:350px){
    .content h3{
        font-size: 32px;
    }
}

@media screen and (min-width:200px) and (max-width:1120px){
    .Arrow{
        top: 5%;
        transform: translateY(0%);
    }
    .Arrow{
        font-size: 20px;
        height: 40px;
        width: 40px;
    }
    .prev-Arrow{
        left: 85%;
        z-index: 1;
    }
    
    .next-Arrow{
        right: 5%;
    }       
}

@media screen and (min-width:200px) and (max-width:800px){
    .prev-Arrow{
        left: 82%;
        z-index: 1;
    }

}

@media screen and (min-width:200px) and (max-width:650px){
    .prev-Arrow{
        left: 79%;
        z-index: 1;
    }
}

@media screen and (min-width:200px) and (max-width:550px){
    .prev-Arrow{
        left: 75%;
        z-index: 1;
    }
}

@media screen and (min-width:200px) and (max-width:450px){
    .prev-Arrow{
        left: 73%;
        z-index: 1;
    }
}

@media screen and (min-width:200px) and (max-width:400px){
    .prev-Arrow{
        left: 65%;
        z-index: 1;
    }
}

@media screen and (min-width:200px) and (max-width:300px){
    .prev-Arrow{
        left: 62%;
        z-index: 1;
    }
}

@media screen and (min-width:200px) and (max-width:420px){
    .br{
        display: block;
    }
}