@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9F9F9;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html , body{
  overflow-x: hidden;
}

.fb_dialog{
  z-index: 75 !important;
}


a , p , h1 , h2 , h3 , h4 , h5 , h6 , label , span , li , label{
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

h1::selection , h2::selection , h3::selection , h4::selection , h5::selection , p::selection , span::selection{
  background: #555;
  color:#FFFFFF;
  text-shadow: none;
}