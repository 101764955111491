nav{
    position: relative;
    width: 100%;
    background: #FFFFFF;
    transition: all .3s ease-in-out;
}

.nav-top{
    position: fixed;
    top: 0;
    z-index: 70;
    transition: all .3s ease-in-out;
    box-shadow: 0 0 40px 5px rgb(0 0 0 / 8%);
    animation: navAnimate 0.5s ease-in-out;
}

@keyframes navAnimate {
    0%{
        transform: translateY(-50px);
    }100%{
        transform: translateY(0px);
    }
}

.nav-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 15px 0px;

}

.left-nav-element{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 15%;

}

.middle-nav-element{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 60%;
}

.right-nav-element{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 30%;
    gap: 12px;
}


.left-nav-element img{
    width: 150px;
    object-fit: contain;
}


.middle-nav-element ul{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.middle-nav-element ul li{
    list-style: none;
}

.middle-nav-element ul li a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    gap: 5px;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    transition: all  linear 0.2s;
    color: #111111;
}

.middle-nav-element .active a{
    color:#EF1D26 ;
}

.middle-nav-element ul li a:hover{
    color: #EF1D26;
    transition: all  linear 0.2s;
}

.theme-btn{
    font-size: 16px;
    color: #FFFFFF;
    padding: 16px 20px;
    transition: all .5s;
    text-transform: capitalize;
    position: relative;
    border-radius: 13px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    border: none;
    background: #EF1D26;
    z-index: 1;
}

.theme-btn::before{
    content: "";
    height: 500px;
    width: 500px;
    background: #111111;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: .5s cubic-bezier(.25,.46,.45,.94);
    z-index: -1;
}

.theme-btn:hover::before {
    transform: translateY(-50%) translateX(-50%) scale(1);
    transition: .8s cubic-bezier(.25,.46,.45,.94);
  }

.theme-btn a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    color: #FFFFFF;
}

.menu{
    font-size: 35px;
    color: #111111;
    font-weight: 400;
    cursor: pointer;
    transition: all  linear 0.2s;
}

.menu:hover{
    color: #EF1D26;
    transition: all  linear 0.2s;
}

@media screen and (min-width:200px) and (max-width:1050px){
    .nav-btn{
        display: none;
    }
}

@media screen and (min-width:200px) and (max-width:900px){
    .middle-nav-element{
        display: none;
    }
}