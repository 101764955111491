.team-container{
    margin-top: 100px;
}

.team-sub-container{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: stretch; 
    gap: 20px;
    height: fit-content;
}

.each-team{
    flex: 1;
    position: relative;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    height: 480px;
    max-width: 300px;
    box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
    transition: all linear 0.5s;
    cursor: pointer;
}

.each-image-container{
    position: relative;
    height: 300px;
    border-radius: 12px;
    overflow: hidden;
}

.each-image-container img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;
    z-index: 2;
    border-radius: 12px;
    cursor: pointer;
    transition: all ease-in-out 0.5s;
}


.team-details{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    flex-direction: column;
}

.team-details h1{
    color: #111111;
    font-size: 20px;
    font-weight: 600;
}

.team-details h4{
    color: #EF1D26;
    font-weight: 600;
}

.team-details p{
    color: #757F95;
    line-height: 22px;
    font-weight: 400;
    font-size: 15px;
}

.each-team ul{
    position: absolute;
    top: 12px;
    right: 22px;
    z-index: 10;
}

.each-team ul li{
    position: relative;
    list-style: none;
    background: #EF1D26;
    padding: 20px;
    border-radius: 50%;
    margin-top: 8px;
    transition: 0.1s all linear;
}

.each-team ul li a{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50% , -50%);
    color: #FFFFFF;
}

.each-team ul li a svg{
    font-size: 20px;
}


.each-team ul li:hover{
    background: #111111;
    transition: 0.1s all linear;
}

.each-team:hover{
    transform: translateY(-12px);
    transition: all ease-in-out 0.5s;
}


@media screen and (min-width:200px) and (max-width:650px) {
    .team-sub-container{
        flex-direction: column;
    }
    .each-team{
        max-width: 95%;
        margin: auto;
        width: 100%;
    }
}