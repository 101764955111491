.vehicale-manage{
    height: fit-content;
}

.main-admin-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow:  inset 0 -1px #c3c9d0;
}

.main-admin-head h1{
    box-shadow:  none;
}

.main-admin-head a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    color: #4b5666;
    font-weight: 600;
    background: #c3c9d0;
    padding: 8px 12px;
    text-decoration: none;
    border-radius: 12px;
}