.top-bar{
    background: #111111;
    width: 100%;
    position: relative;
    z-index: 2;
}

.top-bar::before{
    content: "";
    position: absolute;
    background: #EF1D26;
    clip-path: polygon(0 0,80% 0,100% 100%,0% 100%);
    width: 10%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.top-bar::after{
    content: "";
    position: absolute;
    background: #EF1D26;
    clip-path: polygon(20% 1%,100% 0,100% 100%,0% 100%);
    width: 10%;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.top-bar-container{
    padding: 12px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.conatiner{
    width: 95%;
    max-width: 1100px;
    margin: auto;
}

.top-bar-container a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
}

.top-bar-container p{
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
}

.contact-details-top-bar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.social-details{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
}

.contact-details-top-bar svg{
    color: #EF1D26;
    font-weight: 800;
    font-size: 16px;
}

@media screen and (min-width:200px) and (max-width:750px){
    .top-bar{
        display: none;
    }
}