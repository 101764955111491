.sidebar{
    position: fixed;
    top: 0;
    right: -600px;
    bottom: 0;
    max-width: 500px;
    width: 100%;
    background: #FFFFFF;
    z-index: 90;
    transition: all 0.3s ease-in-out;
}

.sidebar-open{
    right: 0px;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.4s;
}

.sidebar-inner-container{
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    flex-direction: column;
    flex: 1;
}


.sidebar-head{
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
    width: 100%;
}

.sidebar-head img{
    width: 120px;
    object-fit: contain;
}

.closed-icon{
    width: 35px;
    height: 35px;
    background: #111111;
    font-weight: 600;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.closed-icon svg{
    color: #FFFFFF;
    font-size: 25px;
    cursor: pointer;

}

.side-bar-about{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.side-bar-about p {
    color: #757F95;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
}

.side-bar-about h3 , .side-bar-contact h3 , .sidebar-social h3 , .side-bar-quick-links h3{
    font-size: 22px;
    font-weight: 600;
}

.side-bar-quick-links{
    width: 100%;
    display: none;
}

.side-bar-quick-links .links-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    gap: 2px;
    margin-top: 16px;
    width: 100%;
}

.side-bar-quick-links .links-container a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 8px 0px;
    text-decoration: none;
    color: #111111;
    font-weight: 600;
    font-size: 18px;
    flex: 1;
    width: 100%;
}


.side-bar-quick-links .links-container  .active{
    color: #EF1D26;
}

.side-bar-quick-links .links-container a svg{
    color: #EF1D26;
}

.side-bar-contact{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
}

.side-bar-contact-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 18px;
    flex-direction: column;
}

.side-bar-each-contact{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;
    flex-direction: column;
}

.side-bar-each-contact h5{
    font-size: 16px;
    font-weight: 600;
}

.side-bar-each-contact a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: #111111;
}

.side-bar-each-contact a svg{
    color: #EF1D26;
}

.sidebar-social{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
}


.sidebar-each-social{
    width: 40px;
    height: 40px;
    background: #111111;
    font-weight: 600;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-each-social svg{
    color: #FFFFFF;
    font-size: 20px;
}

@media screen and (min-width:200px) and (max-width:500px){
    .sidebar{
        max-width: 300px;
        overflow-y: scroll;
    }
    .sidebar-inner-container{
        padding: 40px 20px;
    }
    
}

@media screen and (min-width:200px) and (max-width:900px){
    .side-bar-about{
        display: none;
    }
    
    .side-bar-quick-links{
        width: 100%;
        display: block;
    }
    .sidebar-social{
        display: none;
    }
}