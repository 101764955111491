.show-case-vehicle-container{
    width: 100%;
    background: #FFFFFF;
    margin-top: 30px;
    border-radius: 12px;
    height: fit-content;
    box-sizing:  0 1px 3px 0 rgba(0 , 0 , 0 , 0.1);
}

.show-case-vehicle-container-inner{
    width: 100%;
    max-width: 97%;
    padding: 20px 0px;
    margin: auto;
}

.show-case-vehicle-container-inner h3{
    font-size: 18px;
    color: #4b5666;
}

.show-case-vehicle-table{
    margin-top: 20px;
}

.image-cell , .button-cell{
    display: flex;
    justify-content: center;
    align-items: center;

}

.image-cell img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.cell-buttton-view{
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(14, 198, 198, .15);
    gap: 3px;
    border: none;
    outline: none;
    color: #29BF6C;
    border-radius: 12px;
    padding: 8px 12px;
    cursor: pointer;
}

