.vehical-add-form-container{
    margin-top: 50px;
}

.vehicale-add-form-all-inputs{
    width: 100%;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,0.5fr));
}

.photo-upload-container {
    margin: 20px 0px;
}

.photo-upload-container-main{
    position: relative;
    width: 100%;
    border-radius: 12px;
    height: 240px;
    background: #FFFFFF;
    border: 2px dashed rgba(0 , 0 , 0 , 0.08);
    margin-top: 15px;
    cursor: pointer;
}

.photo-upload-container label{
    color: #111111;
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
    font-style: normal;
    cursor: pointer;
}

.photo-ippload-container{
    padding: 60px 0px;
    position: absolute;
    top: 50%;
    transform: translateY( -50%);
    width: 100%;
}

.photo-ippload-container p{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #757F95;
}

.photo-uploading-container{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #757F95;
    flex-direction: column;
    gap: 12px;
}

.photo-uploading-container p{
    font-weight: 500;
    font-size: 17px;
}

@media screen and (min-width:200px) and (max-width:600px) {
    .vehicale-add-form-all-inputs{
        grid-template-columns: 1fr; /* Set child elements to take full width */
    }
    
}

.uploaded-image-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0px;
    gap: 12px;
    flex-wrap: wrap;
}

.each-image-uploaded {
    position: relative;
    width: 100px;
    height: 100px;
}

.each-image-uploaded img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;
    z-index: 2;
}

.each-image-uploaded .svg-uploade-image{
    position: absolute;
    z-index: 10;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background: rgba(141, 141, 141, 0.896);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.each-image-uploaded .svg-uploade-image svg{
    color: #FFFFFF;
    font-weight: 800;
    font-size: 20px;
}