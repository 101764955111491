.brand-container{
    margin-top: 100px;
}

.full-brand-conatiner{
    width: 100%;
}

.brands-sub-conainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    animation:  slideing 40s linear infinite;
    width: calc(230px * 14);
    overflow-x: hidden;
}

.wrap-slider{
    margin: auto;
    width: auto;
    overflow-x: hidden;
    position: relative;
}

@keyframes slideing {
    from{
        transform: translateX(0px);
    }to{
        transform: translateX(calc(-230px * 7));
    }
}

.each-brand{
    border-radius: 12px;
    padding: 20px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 200px;
    cursor: pointer;
}

.each-brand img{
    width: 150px;
    height: 100px;
    object-fit: contain;
}

.each-brand h3{
    color: #111111;
    transition: 0.3s linear;
    margin: 8px 0px;
}

.each-brand:hover h3{
    color: #EF1D26;
    transition: 0.3s linear;
}
