.mail-request-popup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    z-index: 90;
    width: 95%;
    max-width: 1200px;
    background: #FFFFFF;
    border-radius: 12px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    
}

.mail-request-open{
    opacity: 1;
    pointer-events: unset;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.7s;
}

.mail-request-popup-inner-conatiner{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
}

.mail-req-image{
    flex: 1;
    position: relative;
    flex-basis: 50%;
}

.mail-req-content{
    flex-basis: 50%;
}

.mail-req-image img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 15px 50% 50% 15px;
}

.mail-req-content-inner{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    flex: 1;
}

.mail-req-content-inner h3{
    font-size: 25px;
    font-weight: 600;
    color: #111111;
}

.mail-req-content-inner h3 span{
    color: #EF1D26;
}

.mr-options{
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    flex-wrap: wrap;
}

.mr-eash-option{
    background: rgba(239,23,33,.05);
    color: #EF1D26;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 20px;
    font-weight: 600;
    padding: 10px 20px;
}

.mr-form{
    width: 100%;
    flex: 1;
}



.each-mr-feild p{
    font-size: 18px;
    font-weight: 500;
}

.mr-custom-feild{
    background: #fbfbfb;
    margin: 10px 0px;
    border-radius: 12px;
    flex-basis: 50%;
    padding: 18px 13px;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.mr-custom-feild input{
    border: none;
    outline: none;
    width: 100%;
    background: none;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.mr-custom-feild input::placeholder{
    color: #474f60;
    line-height: 22px;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.name-email-mr{
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    flex-basis: 50%;
    margin-top: 10px;
}

.mr-email{
    flex-basis: 50%;
}

.mr-name{
    flex-basis: 50%;
}

.mr-request-btn{
    width: 100%;
    margin-top: 30px;
}

.first-mr{
    margin-top: 0;
}

.contact-mr{
    margin-top: 20px;
    
}

.contact-mr .mr-custom-feild{
    padding: 12px 13px;
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 0px;
    margin-left: 0;
    background: transparent !important;
    border: none !important;
    border-radius: none;
    line-height: 25px;
    height: 35px;
    width: 100% !important;
    outline: none;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: none !important;
    border-radius: 3px 0 0 3px;
  }

.mr-request-btn::before{
    height: 800px;
    width: 800px;
}

@media screen and (min-width:200px) and (max-width:1000px){
    .name-email-mr{
        flex-direction: column;
    }
    .mr-email{
        flex-basis: 100%;
        flex: 1;
        width: 100%;
    }
    
    .mr-name{
        flex-basis: 100%;
        flex: 1;
        width: 100%;
    }
}

@media screen and (min-width:200px) and (max-width:800px){    
    .mail-req-image{
        display: none;
    }
    .mail-req-content{
        flex-basis: 100%;
        flex: 1;
        width: 100%;
    }
}

@media screen and (min-width:200px) and (max-width:450px){
    .mr-form p{
        font-size: 15px;
    }
    .mr-eash-option{
        font-size: 12px;
    }
    .mr-custom-feild input{
        font-size: 14px;
    }
    
    .mr-custom-feild input::placeholder{
        font-size: 12px;
    }
    .mail-request-popup-inner-conatiner{
        padding: 20px 10px;
    }
    
    .mr-eash-option{
        font-size: 14px;
        padding: 8px 12px;
    }
    .mr-custom-feild{
        padding: 12px 13px;
    }
    .contact-mr .mr-custom-feild{
        padding: 8px 13px;
    }
    .mail-req-content-inner{
        gap: 15px;
    }
    
}