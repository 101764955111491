.viewd-vehicale-full-black{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    opacity: 0;
    pointer-events: none;
    background-color: transparent;
    transition: all 0.3s linear;
}

.viewd-vehicale-full-black-open{
    opacity: 1;
    pointer-events: unset;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s linear;
}


.cancel-viewd-vehicale{
    position: fixed;
    top: 12px;
    right: 12px;
    background: rgb(92, 92, 92);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 95;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-weight: 800;
    font-size: 25px;
    cursor: pointer;
}


.container-viewd-vehicale{
    position: absolute;
    z-index: 100;
    width: 95%;
    margin: auto;
    max-width: 600px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}


.inner-container-viewd-vehicale{
    width: 100%;
    max-width: 95%;
    margin: auto;
    padding: 20px 0px;
}

.viewd-vehicale-option{
    margin: 25px 0px;
}

.image-container-viewd-vehicale p{
    color: #4b5666;
    font-size: 14px;
    font-weight: 500;
    margin: 12px 0px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    gap: 4px;
}


.image-container-viewd-vehicale p svg{
    font-size: 20px;
}

.image-inner-container-viewd-vehicale{
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(80px,0.5fr));
}

.each-viewed-vehicale{
    height:110px;
}

.each-viewed-vehicale img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;
    z-index: 2;
}

.button-continaer-viewd-vehicale{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex-basis: 50%;
}

.Update-btn{
    background: #29BF6C;
}

.viewd-btn{
    width: 100%;
    flex-basis: 50%;
    display: flex;
    justify-content:center;
    align-items: center;
    text-decoration: none;
    gap: 5px;
    font-weight: 600;
    font-size: 17px;
    color: #FFFFFF;
}

.viewd-btn svg{
    color: #FFFFFF;
}

@media screen and (min-width:200px) and (max-width:500px){
    .each-viewed-vehicale{
        height:80px;
        flex-basis: 19%;
        position: relative;
    }
    .viewd-btn{
        font-size: 14px;
        flex-basis: 100%;
        width: 100%;
        text-align: center;

    }
    .button-continaer-viewd-vehicale{
        flex-direction: column;
    }
}
@media screen and (min-width:200px) and (max-width:400px){
    .each-viewed-vehicale{
        flex-basis: 25%;
    }
}